import '../App.css';

function Navbar() {
  return (
    <>
      <nav className="navbar navbar-expand navbar-dark nav-fill bg-dark">
        <div className="container-fluid">
          <ul className="navbar-nav">
            <li className="nav-item text-light">
              <a className="nav-link" href="#home">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#about">About</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#resume">Resume</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#projects">Projects</a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  )
}

export default Navbar;