import { FaLinkedinIn, FaEnvelope, FaGithub } from 'react-icons/fa';

const Footer = () => {
    return (
      <footer className="footer">
        <div className="footer-container">
          <a href="https://www.github.com/tylerkalkman" target="_blank" rel="noopener noreferrer">
            <FaGithub style={{marginRight:'8px'}}/> tylerkalkman
          </a>
          <h href="" target="_blank" rel="noopener noreferrer">
            <FaEnvelope/> kalkmantyl@gmail.com
          </h>
          <a href="https://www.linkedin.com/in/tylerkalkman/" target="_blank" rel="noopener noreferrer">
            <FaLinkedinIn style={{marginRight:'8px'}}/> Tyler Kalkman
          </a>
        </div>
      </footer>
    );
};
  
export default Footer;