import '../App.css';

function Homepage() {
  return (
    <header className="App-header">
      <h1 className='App-h1'>
        <div className='left'>
          <img src="pfp.webp" alt='tylerkalkman'></img>
        </div>
        <div className='right'>
          <div className='body1'>
            <h1 className='typing'>Tyler Kalkman</h1>
            <h1 className='typing2'>.</h1>
          </div>
          <h4 className='comment'>{'// Leader, critical thinker, problem solver.'}</h4>
          <h2 className='links'>
            <a className="link" href="#about">About me</a>
            <i style={{cursor:'default', color:'#fff'}}> | </i>
            <a className="link" href="#resume">Resume</a>
            <i style={{cursor:'default', color:'#fff'}}> | </i>
            <a className="link" href="#projects">Projects</a>
          </h2>
        </div>
      </h1>
      
    </header>

  )
}

export default Homepage;