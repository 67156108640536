import '../App.css';
import Navbar from './Navbar.js';

function Resume() {
  return (
    <>
      <Navbar/>
      <header className="App-header">
        <object class="pdf" 
                data="Resume.pdf"
                width="100%"
                height="1200">
        </object>
      </header>
    </>
  )
}

export default Resume;