import './App.css';
import React, { useState, useEffect } from 'react';
import Homepage from './components/Homepage.js';
import About from './components/About.js';
import Resume from './components/Resume.js';
import Projects from './components/Projects.js';
import Footer from './components/Footer.js';

function App() {
  // Get the hash part of the URL
  const [hash] = useState(window.location.hash.substring(1));

  const [currentPage,setCurrentPage] = useState(hash);

  const renderPage = (currentPage) => {
    switch (currentPage) {
      case 'home':
        localStorage.setItem('page','home');
        return <Homepage/>;
      case '':
        localStorage.setItem('page','home');
        return <Homepage/>;
      case 'about':
        localStorage.setItem('page','about');
        return <About/>;
      case 'resume':
        localStorage.setItem('page','resume');
        return <Resume/>;
      case 'projects':
        localStorage.setItem('page','projects');
        return <Projects/>;
      default:
        return;
    }
  }

  //load correct page on refresh
  useEffect(
    () => {
      const handleHashChange = () => {
        setCurrentPage(window.location.hash.substring(1));
      };
  
      // Listen for hash change events
      window.addEventListener('hashchange', handleHashChange);

      handleHashChange();

      return () => {
        window.removeEventListener('hashchange', handleHashChange);
      };
    },
    []
  );

  //load correct page on refresh
  useEffect(
    () => {
      if(localStorage.getItem('page') == null){
        setCurrentPage('home');
      } else {
        setCurrentPage(localStorage.getItem('page'));
      }
    },
    []
  );

  return (
    <div className="App">
      {renderPage(currentPage)}
      <Footer/>
    </div>
  );
}

export default App;
